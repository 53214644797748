.player-to-like {
    --rating-choice-image-size: 100px;
    @include media-breakpoint-up(sm) {
        --rating-choice-image-size: 100px;
    }

    &__avatar {
        width: 100%;
        max-width: 390px;
        max-height: 390px;
        border-radius: 50%;
        border: 4px solid #fff;
        filter: drop-shadow(0px 2px 17px rgba(101, 141, 177, 0.35));
        &--meet {
            max-width: 185px;
            max-height: 185px;
        }
    }

    &__buttons {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        gap: 50px;

        @include media-breakpoint-up(sm) {
            gap: 150px;
        }

        .peerrating__choice {
            margin: 0;
        }
    }

    &__description {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        background: $light-nav-bg;
        border-radius: 8px;
        padding: 14px;
        font-size: 16px;

        @include media-breakpoint-up(sm) {
            font-size: 18px;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: -15px;

                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 17px 21px 0;
                border-color: transparent $light-nav-bg transparent transparent;
            }
        }
    }
}
